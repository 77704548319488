import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Seo from "../seo";
import Layout from "../layout";

import Header from "../common/Header";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";
import RegistrationForm from "./RegistrationForm";
import LogInForm from "./LogInForm";
import StyledVideoWrapper from "../shared/StyledVideoWrapper";
import CopyWriterWithSidebar from "../common/CopyWriterWithSidebar";

import { Icon } from "../../globals/icons";
import { StyledDarkThemeWrapper, StyledEGCRegistrationFormWrapper, StyledEgc2022SpeakerDetailsWrapper } from "./styles/StyledComponent";
import { allEgc2022StateDetails } from "./AllEgc2022State.js";
import { StyledDisplay3, StyledSubTitle1, StyledSubTitle2, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
// import { COLORS } from "../../globals/designSystem";
import { translateXZero, flexCenter } from "../shared/CommonStyled";
// import { mq } from "../../globals/utils";

import linkedin from "./images/linkedin.svg";
import twitter from "./images/twitter.svg";

const StyledVideoWrapperPadd = styled(StyledVideoWrapper)`
  padding: 24px 0 48px 0;
`;

const StyledSectionWrapperPos = styled(StyledSectionWrapper)`
  position: relative;
  z-index: 1;
  .leftGlow {
    position: absolute;
    left: 0;
    top: 250px;
    z-index: -1;
    height: -webkit-fill-available;
  }
`;

const Egc2022Speakers = props => {

  const currentEgc2022PageSlug = props.pageContext.slug;
  const currentEgc2022PageFilter = allEgc2022StateDetails.filter(b => b.id === currentEgc2022PageSlug);
  const currentEgc2022Page = currentEgc2022PageFilter[0];
  if (!currentEgc2022PageFilter) {
    if (typeof window !== undefined) {
      window.location.href = "/404";
    }
  }
  const ogImage = { ogImage: currentEgc2022Page.metaTags.metaImg };

  const [isLocalEgc2022, setIsLocalEgc2022] = useState(false);
  const [isRegFormOpen, setIsRegFormOpen] = useState(false);
  const [isLogInFormOpen, setIsLogInFormOpen] = useState(false);
  const [isEmailSubmissionNotFoundError, setIsEmailSubmissionNotFoundError] = useState(false);

  const thumbImgRef = useRef(null);
  const regFormRef = useRef(null);
  const logInFormRef = useRef(null);
  const regHeroBannerBtnRef = useRef(null);
  const logInHeroBannerBtnRef = useRef(null);

  const onLogInFormOpen = () => {
    clearSubmissionNotFoundError();

    setIsRegFormOpen(false);
    setIsLogInFormOpen(true);
  };

  const onSubmissionNotFound = () => {
    setIsEmailSubmissionNotFoundError(true);
    setIsLogInFormOpen(false);
    setIsRegFormOpen(true);
  };

  const onLogInFormClose = () => {
    setIsLogInFormOpen(false);
  };

  const onFormOpen = () => {
    setIsLogInFormOpen(false);
    setIsRegFormOpen(true);
  };

  const onFormClose = () => {
    setIsRegFormOpen(false);
  };

  const clearSubmissionNotFoundError = () => {
    if (isEmailSubmissionNotFoundError) {
      setIsEmailSubmissionNotFoundError(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    document.addEventListener("click", handleClickOutsideLogIn, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener("click", handleClickOutsideLogIn, false);
    };
  });

  const handleClickOutside = event => {
    if (
      regFormRef.current &&
      !regFormRef.current.contains(event.target) &&
      regHeroBannerBtnRef.current &&
      !regHeroBannerBtnRef.current.contains(event.target)
    ) {
      if (!isLogInFormOpen) {
        setIsRegFormOpen(false);
      }
    }
  };

  const handleClickOutsideLogIn = event => {
    if (
      logInHeroBannerBtnRef.current &&
      !logInHeroBannerBtnRef.current.contains(event.target) &&
      thumbImgRef.current &&
      !thumbImgRef.current.contains(event.target) &&
      logInFormRef.current &&
      !logInFormRef.current.contains(event.target)
    ) {
      if (!isRegFormOpen) {
        setIsLogInFormOpen(false);
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const searchPromoCode = searchParams.get("aliId");
    if (!!searchPromoCode && searchPromoCode === "egc_2022_rec") {
      if (typeof window !== undefined) {
        window.localStorage.setItem("egc2022Consent", "true");
      }
    }
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const egc2022Consent = window.localStorage.getItem("egc2022Consent");
        const egcLogIn2022Consent = window.localStorage.getItem("egcLogIn2022Consent");
        if (egc2022Consent) {
          setIsLocalEgc2022(true);
        }
        if(egcLogIn2022Consent) {
          setIsLocalEgc2022(true);
        }
      }
    }
  }, [props.location.search]);
  return (
    <Layout location={props.location} withoutHeader = {true}>
      <Seo
        title={currentEgc2022Page.metaTags.title}
        description={currentEgc2022Page.metaTags.description}
        meta={ogImage}
        canonicalLink={currentEgc2022Page.metaTags.canonicalUrl}
      />
      <StyledDarkThemeWrapper>
        <Header location={props.location} />
        <StyledEGCRegistrationFormWrapper ref={regFormRef} css={isRegFormOpen ? translateXZero : ""}>
          <RegistrationForm
            isEmailSubmissionNotFoundError={isEmailSubmissionNotFoundError}
            onFormClose={onFormClose}
            onLogInFormOpen={onLogInFormOpen}
            location={props.location}
            clearSubmissionNotFoundError={clearSubmissionNotFoundError}
          />
        </StyledEGCRegistrationFormWrapper>
        <StyledEGCRegistrationFormWrapper
          ref={logInFormRef}
          css={isLogInFormOpen ? translateXZero : ""}
        >
          <LogInForm
            onSubmissionNotFound={onSubmissionNotFound}
            onFormOpen={onFormOpen}
            onLogInFormClose={onLogInFormClose}
            location={props.location}
            isLogInFormOpen={isEmailSubmissionNotFoundError}
          />
        </StyledEGCRegistrationFormWrapper>
        <StyledSectionWrapperPos>
          <img className="leftGlow" src="https://graphql-engine-cdn.hasura.io/assets/main-site/egc2022/speaker-left-glow.png" alt="Reg Illu" loading="lazy" />
          <StyledContainerWrapper>
            <StyledEgc2022SpeakerDetailsWrapper>
              <div>
              <StyledDesc2 className="back" variant="neutral_30">
                <Link to="/enterprisegraphql/" css={flexCenter}>
                  <Icon variant="arrow_left" size="xs" color="neutral_30" />
                  Back to conference home page
                </Link>
              </StyledDesc2>
              <div className="posSticky">
                <div className="brandWrapper">
                  <img src={currentEgc2022Page.brandLogo} alt="Brand" loading="lazy"/>
                </div>
              </div>
              </div>
              <div>
                {/* <StyledDesc1 className={((currentEgc2022Page.eventCancelled) ? "cencelledTag" : "")} variant="white" paddingBottom="pb16" fontWeight="font_600">{currentEgc2022Page.dateTime}</StyledDesc1>*/}
                <StyledDisplay3 className="textGradient" paddingBottom="pb24">{currentEgc2022Page.title}</StyledDisplay3>
                {
                  isLocalEgc2022 ? (
                    <StyledVideoWrapperPadd>
                      <div className="videoAspectRatio">
                        <iframe
                          loading="lazy"
                          title={currentEgc2022Page.title}
                          src={currentEgc2022Page.videoLink}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </StyledVideoWrapperPadd>
                  ) : (
                    <div className="videoThumbImg"
                      onClick={() => {
                        clearSubmissionNotFoundError();
                        onLogInFormOpen();
                      }}
                      ref={thumbImgRef}
                    >
                      <img src={currentEgc2022Page.thumbImg} alt="Thumb" loading="lazy" />
                    </div>
                  )
                }
                <StyledDesc1 variant="white">{currentEgc2022Page.description}<br/><br/>
                {
                  currentEgc2022Page.eventCancelled && (<span className="italic">Due to last minute conflicts we are working on republishing at a later day.</span>)
                }
                </StyledDesc1>
                <hr/>
                {
                  currentEgc2022Page.withoutSpeaker && !currentEgc2022Page.speakerDetails ? (
                    <>
                    <StyledSubTitle1 className="textGradient" paddingBottom="pb16" fontWeight="font_bold">
                      Speaker
                    </StyledSubTitle1>
                    <StyledDesc1 variant="white" paddingBottom="pb16" fontWeight="font_600">Coming Soon!</StyledDesc1>
                    </>
                  ) : (
                    <StyledSubTitle1 className="textGradient" fontWeight="font_bold">
                      {
                        currentEgc2022Page.speakerDetails.length > 1 ? "Speakers" : "Speaker"
                      }
                    </StyledSubTitle1>
                  )
                }
                {
                  currentEgc2022Page.speakerDetails ? (
                    <>
                    {
                      currentEgc2022Page.speakerDetails.map((speaker, index) => (
                        <div key={index} className="speakersDetailsWrapper">
                          {
                            speaker.speakerImg ? (
                              <div className="speakerImg">
                                <img src={speaker.speakerImg} alt={speaker.speakerName} loading="lazy" />
                              </div>
                            ) : null
                          }
                          <StyledSubTitle2 variant="white" paddingBottom="pb16" fontWeight="font_bold">{speaker.speakerName}</StyledSubTitle2>
                          {
                            speaker.bio ? (
                              <StyledDesc1 variant="white">{speaker.bio}</StyledDesc1>
                            ) : null
                          }
                          <div className="socialWrapper">
                            {
                              speaker.twitterUrl ? (
                                <a className="socialImg" href={speaker.twitterUrl} target="_blank" rel="noopener noreferrer">
                                  <img src={twitter} alt="Twitter" />
                                </a>
                              ) : null
                            }
                            {
                              speaker.linkedInUrl ? (
                                <a className="socialImg" href={speaker.linkedInUrl} target="_blank" rel="noopener noreferrer">
                                  <img src={linkedin} alt="LinkedIn" />
                                </a>
                              ) : null
                            }

                          </div>
                        </div>
                      ))
                    }
                    </>
                  ) : null
                }

              </div>
            </StyledEgc2022SpeakerDetailsWrapper>
          </StyledContainerWrapper>
        </StyledSectionWrapperPos>
        <CopyWriterWithSidebar isDarkMode = {true}/>
      </StyledDarkThemeWrapper>
    </Layout>
  );
};

export default Egc2022Speakers;
